import React from 'react';
import { HiX } from 'react-icons/hi';
export interface CloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const CloseButton = React.forwardRef<HTMLButtonElement, CloseButtonProps>(
    ({ className = '', 'aria-label': ariaLabel = 'Close', ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            className={`text-dark-green bg-transparent text-3xl ${className}`}
            aria-label={ariaLabel}
            {...props}>
            <HiX />
        </button>
    ),
);

CloseButton.displayName = 'CloseButton';

export default CloseButton;
