import React, { Fragment } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';

export interface ModalProps extends React.PropsWithChildren {
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
    bg?: 'white' | 'light' | 'dark';
    show: boolean;
    onClose: () => void;
}
const sizeClasses = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
    '3xl': 'max-w-3xl',
};

const bgClasses = {
    white: 'bg-white',
    light: 'bg-lightest-grey',
    dark: 'bg-dark-green',
};

const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
    ({ size = 'sm', bg = 'white', show, onClose, children }: ModalProps, ref) => {
        return (
            <Transition appear show={show} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={onClose}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="bg-dark-green/75 fixed inset-0" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <DialogPanel
                                    className={`relative w-full ${sizeClasses[size]} transform overflow-hidden ${bgClasses[bg]} p-6 text-left align-middle drop-shadow-lg transition-all`}>
                                    {children}
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        );
    },
);

Modal.displayName = 'Modal';

export default Modal;
